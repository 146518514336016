
import { RH_IMAGES_HOSTS } from '@/services/constants';

export default {
  name: 'ImgLoader',

  props: {
    imgPath: {
      type: String,
      required: true,
    },
    modifiers: {
      type: Object,
      default: () => ({}),
    },
    altImg: {
      type: String,
      default: '',
    },
    isLocalized: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isDevEnv() {
      return process.env.NUXT_ENV_APP_ENV === 'development';
    },
    cdnUrl() {
      if (process.env.NUXT_ENV_APP_ENV === 'production') {
        return RH_IMAGES_HOSTS.PRODUCTION;
      }
      return this.isDevEnv ? RH_IMAGES_HOSTS.LOCALHOST : RH_IMAGES_HOSTS.STAGING;
    },
    imgSrc() {
      return this.getImagePath();
    },
    imgModifiers() {
      return {
        f: 'auto',
        ...this.modifiers,
      };
    },
  },

  methods: {
    getLocaleLanguageCode() {
      return (
        this.$i18n.locales
          ?.find((locale) => locale.code === this.$i18n.defaultLocale)
          ?.acceptedLanguage?.substr(0, 2) || 'en'
      );
    },
    getImagePath() {
      const localeLanguageCode = this.getLocaleLanguageCode();
      const useLocalizedVersion = this.isLocalized;

      return useLocalizedVersion
        ? `${this.cdnUrl}/images/${localeLanguageCode}/${this.imgPath}`
        : `${this.cdnUrl}/images/${this.imgPath}`;
    },
  },
};
